
















































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import FilterLabel from "@monorepo/uikit/src/components/tableViews/FilterLabel.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import DateTimePeriod from "@monorepo/uikit/src/components/tableViews/DateTimePeriod.vue";
import YearPicker from "@monorepo/uikit/src/components/tableViews/YearPicker.vue";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import TreeFilter from "@monorepo/uikit/src/components/common/TreeFilter.vue";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import Vue from "vue";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { isEmpty } from "lodash";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/DocumentsBookReportView/utils/defaultCurrentFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    YearPicker,
    FilterLabel,
    TimePicker,
    SelectFilter,
    FindButton,
    ToggleFilters,
    Autocomplete,
    ClearFilters,
    TreeFilter,
    DateTimePeriod,
  },
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    viewUniqKey: {
      type: String,
      default: "",
    },
    filtersElements: {
      type: Array,
      default: () => [],
    },
    setCurrentFilters: {
      type: Function,
      default: () => ({}),
    },
    autocompleteFiltersKeys: {
      type: Array,
    },
    selectItemsObj: {
      type: Object,
      default: () => ({}),
    },
    chipsObj: {
      type: Object,
    },
    buttonsMarginTop: {
      type: Number,
      default: 40,
    },
    findButtonTitle: {
      type: String,
    },
    isHasTopTable: {
      type: Boolean,
      default: false,
    },
    isShowTemplates: {
      type: Boolean,
      default: true,
    },
    isShowAutoButton: {
      type: Boolean,
      default: false,
    },
    isChangeMargin: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    currentDocumentView: {
      type: String,
      default: ViewMode.TABLE,
    },
    isReportForm: {
      type: Boolean,
      default: false,
    },
    isShowReportToggle: {
      type: Boolean,
      default: false,
    },
    getQueryCb: {
      type: Function,
    },
    changeCurrentFiltersBeforeAdd: {
      type: Function,
    },
    defaultFiltersDivHeight: {
      type: Number,
      default: 88,
    },
  },
  data() {
    return {
      defaultFilterPlaceholder,
      elementStatuses,
      educationIds,
      menu: false,
    };
  },
  computed: {
    resultClassName(): (filterItem: { className?: string; title?: string }) => string {
      return (filterItem) => {
        if (filterItem.className?.includes("mt")) {
          return filterItem.className;
        }
        return `${filterItem.className || ""} ${filterItem.title ? "mt-4" : "mt-6"}`;
      };
    },
    isTableLoading(): boolean {
      return this.$store.getters[`${this.sectionName}/isTableLoading`];
    },
    isLoadingToggleFilters(): boolean {
      return this.$store.getters[`${this.sectionName}/isLoadingToggleFilters`];
    },
    fieldFilters(): Record<string, unknown> {
      return this.$store.getters[`${this.sectionName}/fieldFilters`];
    },
    searchTemplates(): ISearchTemplate[] {
      return this.$store.getters[`${this.sectionName}/searchTemplates`];
    },
    resultFiltersElements(this: { filtersElements: Record<string, unknown>[]; currentDocumentView: ViewMode }) {
      return this.filtersElements.filter(
        (filterItem: Record<string, unknown>) =>
          !filterItem.availableDocumentTypes || (filterItem.availableDocumentTypes as ViewMode[]).includes(this.currentDocumentView)
      );
    },
    resultButtonsMarginTop(): number {
      return !this.isChangeMargin ? this.buttonsMarginTop : this.changeMargin ? 60 : 40;
    },
    statusField(): string {
      return (
        (this.filtersElements as { value: string; type: string }[]).find((item: { value: string; type: string }) => item.type === "selectStatus")
          ?.value || ""
      );
    },
    refreshReportKeys(): string[] {
      return ((this.filtersElements as Record<string, unknown>[])?.reduce((acc: string[], item: Record<string, unknown>) => {
        acc = acc.concat((item.dateValues || [item.value]) as string[]);
        return acc;
      }, []) || []) as string[];
    },
    resultElementStatuses(): unknown[] {
      const statusItemsName = (this.filtersElements as { selectItemsField?: string; type: string }[]).find(
        (item: { selectItemsField?: string; type: string }) => item.type === "selectStatus"
      )?.selectItemsField;
      return this.selectItemsObj && statusItemsName ? this.selectItemsObj[statusItemsName] : this.elementStatuses;
    },
    status: {
      get(): string[] {
        return this.currentFilters[this.statusField] as string[];
      },
      set(value: string[]): void {
        if (!value.length) {
          this.currentFilters[this.statusField] = [(this.resultElementStatuses[0] as { value: string }).value];
        } else {
          this.currentFilters[this.statusField] = value;
        }
      },
    },
    changeMargin() {
      return (this as unknown as { notHiddenKeys: string[] }).notHiddenKeys.length > 3;
    },
  },
  methods: {
    handleEnter() {
      console.log("yes");
    },
    clearFilters() {
      this.$store.dispatch(`${this.sectionName}/clearFilters`);
    },
    refreshScroll() {
      this.$store.dispatch(`${this.sectionName}/refreshScroll`);
    },
    addFilter(value: Record<string, SORT_TYPE>) {
      this.$store.dispatch(`${this.sectionName}/addFilter`, value);
    },
    saveSearchTemplates(value: Partial<ISearchTemplate>) {
      this.$store.dispatch(`${this.sectionName}/saveSearchTemplates`, value);
    },
    clearEventList() {
      this.$store.dispatch(`${this.sectionName}/clearEventList`);
    },
    clearReportFilters() {
      this.currentFilters = setCurrentFilters();
      this.addFilter(this.currentFilters as Record<string, SORT_TYPE>);
      if (this.isShowReportToggle) {
        this.$emit("change", true);
        this.isShowToggle = false;
      }
      this.clearEventList();
    },
    autocompleteAction(actionName: string): (value: string) => Promise<Record<string, unknown>[]> {
      return async (value: string) => {
        return await this.$store.dispatch(`${this.sectionName}/${actionName}`, value);
      };
    },
    getTreeData() {
      this.$store.dispatch(`${this.sectionName}/getTreeData`);
    },
    getEventTopList() {
      this.$store.dispatch(`${this.sectionName}/getEventTopList`);
    },
    refreshReport() {
      if (this.refreshReportKeys.some((key) => isEmpty(this.currentFilters[key]))) {
        showNotification("Заполните все фильтры для формирования отчета");
        return;
      }
      this.addFilter(this.currentFilters as Record<string, SORT_TYPE>);
      if (this.isHasTopTable) {
        this.getEventTopList();
      }
      this.refreshScroll();
      if (this.isShowReportToggle) {
        this.isShowToggle = true;
      }
    },
    saveSearchAsSelected(event: { event: string; filterItem?: { value: string } }) {
      if (event.filterItem?.value === "keyword" && event.event) {
        this.currentFilters[event.filterItem.value] = this.autocompleteItemsResultLists[event.filterItem.value][0];
      }
    },
    changeMenuItem(filterItem: Record<string, unknown>) {
      const menuItemsArr = (filterItem.menuItems as { value: string }[]).map((item) => item.value);
      if (menuItemsArr.map((value) => this.currentFilters[value]).every((item) => !item)) {
        Vue.nextTick(() => {
          this.currentFilters[menuItemsArr[0]] = true;
        });
      }
    },
    refreshChipFilter(filterKey: string, value: string) {
      if (this.currentFilters[filterKey] !== value) {
        this.currentFilters[filterKey] = value;
        this.refresh();
      }
    },
  },
  watch: {
    currentDocumentView: {
      async handler(this: { getHiddenKeys: () => void; value: boolean; updateFiltersDivHeight: () => void }) {
        if (!this.value) {
          Vue.nextTick(() => {
            this.getHiddenKeys?.();
          });
        }
      },
    },
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((props.setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
        currentDocumentView: ViewMode;
      },
      props.sectionName,
      {},
      currentFilters,
      props.setCurrentFilters,
      props.filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle,
      props.isHasTopTable,
      props.isReportForm,
      props.isShowReportToggle,
      ref(0),
      props.defaultFiltersDivHeight,
      props.getQueryCb,
      props.changeCurrentFiltersBeforeAdd
    );
    const { getAutocompleteItems } = props.autocompleteFiltersKeys
      ? useAutocomplete(
          root,
          props.sectionName,
          currentFilters,
          autocompleteItemsResultLists,
          isLoadingAutocomplete,
          props.autocompleteFiltersKeys as string[]
        )
      : { getAutocompleteItems: null };

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
