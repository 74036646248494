var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{ref:_vm.refTableName,staticClass:"monitoring-table monitoring-table__content",attrs:{"id":_vm.tableScrollId},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('transition-group',{attrs:{"name":"list","tag":"tbody"}},[_vm._l((_vm.resultData),function(event,eventIndex){return [_c('tr',{key:_vm.tableRowKey(event) || eventIndex,class:{
            'monitoring-table__tr-initial': !_vm.isShowAnimation,
            'monitoring-table__tr': _vm.isShowAnimation,
            'monitoring-table__tr-active': (_vm.selectedIdsElements && _vm.selectedIdsElements[event.id]) || (_vm.openedId && _vm.openedId === event.id),
          },attrs:{"data-test-id":"tableRow"},on:{"mousemove":_vm.addAnimation,"mouseleave":_vm.clearAnimation}},_vm._l((_vm.headers),function(header,index){return _c('td',{key:("" + (event.id) + (header.id)),class:[
              'monitoring-table__td pl-3 pr-3 pt-2 pb-2',
              Object.assign({}, {'monitoring-table__td_error': !index && _vm.isElementDeleted(event[_vm.statusFieldName])},
                _vm.additionalClassObj(index, event)) ],style:({ width: header.width ? ((header.width) + "px") : '', textAlign: header.textAlign || '' }),attrs:{"data-test-id":"tableCell"}},[(!index && _vm.isShowOpenCardBtn)?_c('OpenCardButton',{attrs:{"title":_vm.openCardButtonTitle},on:{"open":function($event){return _vm.openModal({ value: 'openModal', isLink: true }, event)}}}):_vm._e(),(!index)?_vm._t("additionalOpenCard",null,{"event":event}):_vm._e(),(header.value === _vm.headerCheckboxValue)?_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"data-test-id":"tableCellCheckbox","ripple":false,"input-value":_vm.selectedIdsElements[event.id]},on:{"click":function($event){$event.preventDefault();return _vm.clickCheckbox(event.id)}}}):_vm._e(),(header.slotName === 'itemList')?_vm._l((_vm.itemList(event, header.value)),function(item,index){return _c('div',{key:item.id,class:[
                  'mb-2 table__funds-list',
                  {
                    table__link: header.isLink,
                    'table__item-opacity': _vm.isElementDeleted(event[_vm.statusFieldName]),
                  } ],on:{"click":function($event){return _vm.openModal(header, item)}}},[_vm._v(" "+_vm._s(item.name || item)),(_vm.isShowSpread && index + 1 === _vm.showItemsNumber)?_c('span',[_vm._v("...")]):_vm._e()])}):(header.slotName)?_vm._t(header.slotName,null,{"event":event,"header":header,"eventIndex":eventIndex}):(header.value === _vm.idCellFieldName)?_c('IdCell',{attrs:{"value":event[_vm.idCellFieldName],"is-link":header.isLink}}):_c('div',{class:{
                table__link: header.isLink,
                'table__item-opacity': _vm.isElementDeleted(event[_vm.statusFieldName]),
                'table__item-opacity_red': _vm.isElementDeleted(event[_vm.statusFieldName]) && header.value === _vm.statusFieldName,
              },on:{"click":function($event){return _vm.openModal(header, event)}}},[_vm._v(" "+_vm._s(event[header.value])+" ")])],2)}),0),(event.highlights && event.highlights.length)?_c('tr',{key:(_vm.tableRowKey(event) || eventIndex) + 'highlights'},[_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('TableSearchResults',{attrs:{"event":event}})],1)]):_vm._e()]})],2)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }