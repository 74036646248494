





































import { defineComponent } from "@vue/composition-api";
import CopyBtn from "@monorepo/uikit/src/components/tableViews/CopyBtn.vue";

export default defineComponent({
  name: "CardModalInfoElement",
  components: {
    CopyBtn,
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  props: {
    isShowContentTooltip: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    isCopied: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    activeLink: {
      type: Boolean,
      default: false,
    },
    info: {
      type: [String, Number],
      default: "",
    },
    isShowErrorText: {
      type: Boolean,
      default: false,
    },
    isWhiteBackground: {
      type: Boolean,
      default: false,
    },
    contentTooltip: {
      type: [Function, String, Number],
      default: null,
    },
  },
  computed: {
    infoResult(): string | number {
      return this.type === "password" && !this.isShowPassword ? "".padStart(this.info?.toString?.().length, "•") : this.info;
    },
  },
  methods: {
    clickCb() {
      if (this.info === "") {
        return false;
      }
      this.$emit("click");
      return;
    },
  },
});
