import { fields } from "./fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const filtersElements = [
  {
    value: fields.OIK_NAME,
    title: "Название ОИК",
    tooltip: "Полное наименование ОИК - Выпадающий список",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 310,
    className: "mt-5",
  },
  {
    value: fields.CREATE_DATE,
    dateValues: [fields.CREATE_DATE_FROM, fields.CREATE_DATE_TO],
    title: "Период создания",
    tooltip: "Интервал времени в пределах которого был создан АК - два календаря",
    type: "datePeriod",
    width: 226,
  },
  {
    value: fields.LEGAL_RENEWAL_DATE,
    dateValues: [fields.LEGAL_RENEWAL_DATE_FROM, fields.LEGAL_RENEWAL_DATE_TO],
    title: "Дата продления юридической значимости",
    tooltip: "Интервал времени в пределах которого необходимо провести процедуру продления юридической значимости - два календаря",
    type: "datePeriod",
    width: 226,
    className: "mt-0",
  },
  {
    value: fields.ID,
    title: "Идентификатор АК",
    tooltip: "Ввод вручную",
    type: "text",
    width: 287,
    mask: cloneDeep(standardMask.number),
  },
  {
    value: fields.CHECK_DATE,
    dateValues: [fields.CHECK_DATE_FROM, fields.CHECK_DATE_TO],
    title: "Период проверки",
    tooltip: "Интервал времени когда была проведена проверка АК - два календаря",
    type: "datePeriod",
    width: 226,
  },
  {
    value: fields.STATUS,
    title: "Статус",
    tooltip: "Выпадающий список",
    type: "select",
    selectItemsField: "statuses",
    width: 315,
  },
  {
    value: fields.TK_ID,
    title: "Идентификатор ТК",
    tooltip: "Ввод вручную",
    type: "text",
    width: 287,
  },
  {
    value: fields.AK_DOC_TYPE,
    title: "Тип документа в АК",
    tooltip: "Выпадающий список",
    type: "select",
    selectItemsField: "types",
    width: 285,
  },
  {
    value: fields.ID_DOC,
    title: "Идентификатор документа в АК",
    tooltip: "Ввод вручную",
    type: "text",
    width: 275,
  },
];
