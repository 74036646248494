import { ComponentInstance, onBeforeMount, onUnmounted } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";

const useAsyncExport = (root: ComponentInstance, moduleName: string): void => {
  const store = root.$store;

  const setIsExportFileLoading = (value: boolean) => {
    store.commit("app/setIsExportFileLoading", value, { root: true });
  };

  const getAsyncExportData = (uuid: string) => {
    store.dispatch(`${moduleName}/getAsyncExportData`, uuid);
  };

  const getAsyncExport = (notification: Record<string, unknown>) => {
    switch (notification?.id) {
      case 28:
        {
          const resultArray = (notification.description as string).split("\n");
          const uuid = resultArray[1]?.split(": ")[1];
          if (uuid) {
            getAsyncExportData(uuid);
          } else {
            setIsExportFileLoading(false);
          }
        }
        break;
      case 29:
      case 31:
        setIsExportFileLoading(false);
        break;
      default:
        break;
    }
  };

  onBeforeMount(() => {
    eventBus.$on("newNotification", getAsyncExport);
  });

  onUnmounted(() => {
    eventBus.$off("newNotification", getAsyncExport);
  });
};

export default useAsyncExport;
